import apisauce from "apisauce";
import { api_key } from "./apiKey";
import { baseUrl } from "./baseUrl";

const create = (baseURL = baseUrl) => {
  const giftCardsApi = apisauce.create({
    baseURL,
  });

  const purchaseGiftCard = (requestObj) => {
    requestObj.api_key = api_key;
    return giftCardsApi.post("addnewgiftcard.php", JSON.stringify(requestObj));
  };

  const checkGiftCard = (
    businessId,
    locationId,
    giftCardNumber,
    giftCardPin
  ) => {
    const formData = new FormData();
    formData.append("business_id", businessId);
    formData.append("location_id", locationId);
    formData.append("gift_card_number", giftCardNumber);
    formData.append("gift_card_pin", giftCardPin);
    formData.append("api_key", api_key);
    return giftCardsApi.post("verifycarddetails.php", formData);
  };

  const reloadGiftCard = (requestObj) => {
    requestObj.api_key = api_key;
    return giftCardsApi.post(
      "rechargegiftcard.php",
      JSON.stringify(requestObj)
    );
  };

  const purchaseGiftCardClover = (requestObj) => {
    requestObj.api_key = api_key;
    return giftCardsApi.post(
      "addnewgiftcardclover.php",
      JSON.stringify(requestObj)
    );
  };
  const purchaseGiftCardPoynt = (requestObj) => {
    requestObj.api_key = api_key;
    return giftCardsApi.post(
      "addnewgiftcardpoynt.php",
      JSON.stringify(requestObj)
    );
  };

  const reloadGiftCardClover = (requestObj) => {
    requestObj.api_key = api_key;
    return giftCardsApi.post(
      "rechargegiftcardclover.php",
      JSON.stringify(requestObj)
    );
  };

  const reloadGiftCardPoynt = (requestObj) => {
    requestObj.api_key = api_key;
    return giftCardsApi.post(
      "rechargegiftcardpoynt.php",
      JSON.stringify(requestObj)
    );
  };

  const couponValidate = (requestObj) => {
    requestObj.api_key = api_key;
    return giftCardsApi.post(
      "validate_coupon.php",
      JSON.stringify(requestObj)
    );
  };

  return {
    purchaseGiftCard,
    checkGiftCard,
    reloadGiftCard,
    purchaseGiftCardClover,
    reloadGiftCardClover,
    purchaseGiftCardPoynt,
    reloadGiftCardPoynt,
    couponValidate,
  };
};

export default {
  create,
};
