import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  FormControl,
  Box,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import styles from "./style.module.scss";
import moment from "moment";
import "react-add-to-calendar/dist/react-add-to-calendar.css";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import "moment/locale/fr";
import MaskedInput from "react-text-mask";
import RestaurantActions, {
  RestaurantSelectors,
} from "../../Redux/RestaurantRedux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
} from "@fortawesome/free-brands-svg-icons";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Clear";
import giftCardsApi from "../../Services/giftCardsApi";
import { toast } from "react-toastify";

var valid = require("card-validator");

const classes = (theme) => {
  return {
    cardTitle: {
      backgroundColor: theme.palette.secondary.main,
      padding: 16,
      textAlign: "center",
      color: "white",
      fontSize: 16,
      fontWeight: 600,
    },
  };
};

function CreditAmericanCardMasking(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[
        /[1-9]/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      showMask
    />
  );
}

function CreditNormalCardMasking(props) {
  const { inputRef, ...other } = props;

  console.log(props);

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={
        props.id == "_american"
          ? [
            /[1-9]/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]
          : [
            /[1-9]/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]
      }
      showMask
    />
  );
}
function ExpityDateMasking(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[/\d/, /\d/, "/", /\d/, /\d/]}
      showMask
    />
  );
}
function PostalCodeMasking(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[/^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/]}
      showMask
    />
  );
}

class CreditCard extends Component {
  constructor(props) {
    super(props);
    let couponInput = "";
    this.state = {
      creditCardNumber: "",
      creditCardNumberError: false,
      nameOnCreditCard: "",
      nameOnCreditCardError: false,
      creditCardCvv: "",
      creditCardCvvError: false,
      creditCardExpiryDate: "",
      creditCardExpiryDateError: false,
      creditCardZipCode: "",
      creditCardZipCodeError: false,
      cardType: null,
      errorInForm: false,
      showCoupon: false,
      couponInput: couponInput,
      couponValid: true,
      couponInfoForReorder: {},
      couponProcess: false,
      discountAmount: "",
      couponType: "",
      couponValue: "",
      couponAppliedSuccess: false,
    };
  }

  changeLanguage = (lan) => {
    if (lan == "en") {
      moment.locale("fr");
      this.props.i18n.changeLanguage("fr");
    } else {
      moment.locale("en");
      this.props.i18n.changeLanguage("en");
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCreditCardChange = (name) => (event) => {
    const value = event.target.value;
    const validity = valid.number(value);
    var type = null;
    if (validity.card && validity.card.type) {
      type = validity.card.type;
    }

    if (validity.isValid) {
      this.setState({
        creditCardNumber: value,
        cardType: type,
        creditCardNumberError: false,
      });
    } else {
      this.setState({
        creditCardNumber: value,
        cardType: type,
      });
    }
  };

  handleExpiryChange = () => (event) => {
    const value = event.target.value;
    const validity = valid.expirationDate(value);
    if (!validity.isValid) {
      this.setState({
        creditCardExpiryDate: value,
      });
    } else {
      this.setState({
        creditCardExpiryDateError: false,
        creditCardExpiryDate: value,
      });
    }
  };

  handleZipCodeChange = () => (event) => {
    const value = event.target.value;
    const validity = valid.postalCode(value);
    if (!validity.isValid) {
      this.setState({
        creditCardZipCode: value,
      });
    } else {
      this.setState({
        creditCardZipCodeError: false,
        creditCardZipCode: value,
      });
    }
  };

  handleCvvChange = () => (event) => {
    const value = event.target.value;
    let validity = valid.cvv(value);
    if (this.state.cardType == "american-express") {
      validity = valid.cvv(value, 4);
    }
    if (!validity.isValid) {
      this.setState({
        creditCardCvv: value,
      });
    } else {
      this.setState({
        creditCardCvvError: false,
        creditCardCvv: value,
      });
    }
  };

  closeCoupon = () => {
    this.setState({
      showCoupon: false,
      couponValid: true,
      couponInput: "",
    });
  };

  applyCoupon = () => {
    this.setState({
      couponProcess: true,
    });

    const requestObj = {
      api_key: null,
      business_id: this.props.restaurantDetails?.businessId,
      location_id: this.props.restaurantDetails?.locationId,
      coupon_code: this.state.couponInput,
      recipient_email: this.props.recipientEmail,
      amount: this.props.amount,
    };
    // console.log('Coupon Request Data', requestObj);

    let apiService = giftCardsApi.create();
    this.setState({ loading: true });
    apiService.couponValidate(requestObj).then((response) => {
      if (response.ok && response.data.status != "Failure") {
        this.setState({
          loading: false,
          showCoupon: false,
          couponProcess: false,
          couponAppliedSuccess: true,
          discountAmount: response.data.discount_amount,
          couponType: response.data.coupon_type,
          couponValue: response.data.coupon_value,
        });
        this.props.onCouponApplied(response.data.discount_amount, this.state.couponInput);
      } else {
        if (response.data.status == "Failure" && response.data.status_message) {
          toast.error(response.data.status_message);
        } else {
          toast.error(this.props.t("Coupon not worked! Please try again!"));
        }
        this.setState({
          loading: false,
          showCoupon: false,
          couponInput: "",
          couponProcess: false,
          couponAppliedSuccess: false,
        });
      }
    });
  };

  purchase = () => {
    //Check all inputs

    const cardNumberValidity = valid.number(this.state.creditCardNumber);
    let cvvValidity = valid.cvv(this.state.creditCardCvv);

    if (this.state.cardType == "american-express") {
      cvvValidity = valid.cvv(this.state.creditCardCvv, 4);
    }

    const expiryValidity = valid.expirationDate(
      this.state.creditCardExpiryDate
    );

    let creditCardZipCodeError = false;

    if (
      !/^(\d{5}(-\d{4})?|[a-zA-Z]\d[a-zA-Z] ?\d[a-zA-Z]\d)$/.test(
        this.state.creditCardZipCode
      )
    ) {
      creditCardZipCodeError = true;
    }

    if (
      !cardNumberValidity.isValid ||
      (!cvvValidity.isValid) ||
      !expiryValidity.isValid ||
      (this.state.nameOnCreditCard.length < 3 || this.state.nameOnCreditCard.length > 30) ||
      creditCardZipCodeError
    ) {
      this.setState({
        creditCardNumberError: !cardNumberValidity.isValid,
        creditCardCvvError: !cvvValidity.isValid,
        creditCardExpiryDateError: !expiryValidity.isValid,
        nameOnCreditCardError: (this.state.nameOnCreditCard.length < 3 || this.state.nameOnCreditCard.length > 30),
        creditCardZipCodeError: creditCardZipCodeError,
      });
    } else {
      let expiry = this.state.creditCardExpiryDate.split("/");
      const strippedNumber = this.state.creditCardNumber.replace(/\s+/g, "");
      const strippedCode = this.state.creditCardZipCode.replace(/\s+/g, "");
      const request = {
        creditCardNumber: strippedNumber,
        nameOnCreditCard: this.state.nameOnCreditCard,
        creditCardCvv: this.state.creditCardCvv,
        expiryMonth: expiry[0],
        expiryYear: expiry[1],
        creditCardZipCode: strippedCode,
      };
      this.props.onPurchase(request);
    }
  };

  render() {
    const { classes } = this.props;
    const { t, i18n } = this.props;
    if (navigator.language.indexOf("fr") > -1) {
      if (i18n.language !== "fr") {
        moment.locale("fr");
        this.props.i18n.changeLanguage("fr");
      }
    }
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl
              className={styles.formControl}
              fullWidth={true}
              variant="outlined"
            >
              <TextField
                label={this.props.t("Credit Card")}
                variant="outlined"
                autoFocus
                error={this.state.creditCardNumberError}
                id={
                  this.state.cardType == "american-express"
                    ? "_american"
                    : "_other"
                }
                helperText={
                  this.state.creditCardNumberError
                    ? this.props.t("Please enter valid credit card number!")
                    : ""
                }
                InputProps={{
                  inputComponent: CreditNormalCardMasking,
                  isAmerican: this.state.cardType == "american-express",
                  value: this.state.creditCardNumber,
                  onChange: this.handleCreditCardChange("creditCardNumber"),
                  startAdornment: (
                    <InputAdornment position="start">
                      {this.state.cardType == "visa" && (
                        <FontAwesomeIcon icon={faCcVisa} />
                      )}
                      {this.state.cardType == "mastercard" && (
                        <FontAwesomeIcon icon={faCcMastercard} />
                      )}
                      {this.state.cardType == "american-express" && (
                        <FontAwesomeIcon icon={faCcAmex} />
                      )}
                      {this.state.cardType !== "visa" &&
                        this.state.cardType !== "mastercard" &&
                        this.state.cardType !== "american-express" && (
                          <FontAwesomeIcon icon={faCreditCard} />
                        )}
                    </InputAdornment>
                  ),
                }}
                labelWidth={60}
              />
            </FormControl>
          </Grid>
          <Grid item xs={7} sm={6}>
            <FormControl
              className={styles.formControl}
              fullWidth={true}
              variant="outlined"
            >
              <TextField
                label={this.props.t("Name on Card")}
                value={this.state.nameOnCreditCard}
                variant="outlined"
                inputProps={{ maxLength: 30 }}
                onChange={(event) => {
                  if (event.target.value.length < 3 || event.target.value.length > 30) {
                    this.setState({
                      nameOnCreditCard: event.target.value,
                    });
                  } else {
                    this.setState({
                      nameOnCreditCard: event.target.value,
                      nameOnCreditCardError: false,
                    });
                  }
                }}
                error={this.state.nameOnCreditCardError}
                helperText={
                  this.state.nameOnCreditCardError
                    ? this.props.t("Please enter valid Name on Card!")
                    : ""
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={5} sm={4}>
            <FormControl
              className={styles.formControl}
              fullWidth={true}
              variant="outlined"
            >
              <TextField
                label={this.props.t("Expiry Date (MM/YY)")}
                variant="outlined"
                error={this.state.creditCardExpiryDateError}
                helperText={
                  this.state.creditCardExpiryDateError
                    ? this.props.t("Please enter valid expiry date!")
                    : ""
                }
                InputProps={{
                  inputComponent: ExpityDateMasking,
                  value: this.state.creditCardExpiryDate,
                  onChange: this.handleExpiryChange(),
                }}
                labelWidth={60}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormControl
              className={styles.formControl}
              fullWidth={true}
              variant="outlined"
            >
              <TextField
                label="CVV"
                value={this.state.creditCardCvv}
                variant="outlined"
                inputProps={{ maxLength: 4 }}
                onChange={this.handleCvvChange()}
                error={this.state.creditCardCvvError}
                helperText={
                  this.state.creditCardCvvError ? this.props.t("Please enter valid cvv!") : ""
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={4}>
            <FormControl
              className={styles.formControl}
              fullWidth={true}
              variant="outlined"
            >
              <TextField
                label={this.props.t("Zip/Postal Code")}
                value={this.state.creditCardZipCode}
                variant="outlined"
                inputProps={{ maxLength: 7 }}
                onChange={(event) => {
                  if (
                    !/^(\d{5}(-\d{4})?|[a-zA-Z]\d[A-Z] ?\d[a-zA-Z]\d)$/.test(
                      event.target.value
                    )
                  ) {
                    this.setState({
                      creditCardZipCode: event.target.value,
                      // creditCardZipCodeError: true,
                    });
                  } else {
                    this.setState({
                      creditCardZipCode: event.target.value,
                      creditCardZipCodeError: false,
                    });
                  }
                }}
                error={this.state.creditCardZipCodeError}
                helperText={
                  this.state.creditCardZipCodeError
                    ? this.props.t("Please enter valid Postal Code!")
                    : ""
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>

            <Grid container justify="flex-start">
              {!this.props.isReload && (
                <Grid>
                  {!this.state.couponAppliedSuccess && (
                    <Grid item xs={12} sm={3}>
                      <Button
                        color="secondary"
                        variant="contained"
                        style={{ width: 200, marginBottom: 20 }}
                        size="large"
                        onClick={() =>
                          this.setState({ showCoupon: true })
                        }
                        classes={{ sizeLarge: styles.largeButton }}
                      >
                        Apply Coupon
                    </Button>
                    </Grid>
                  )}
                  {this.state.couponAppliedSuccess && (
                    <Grid item xs={12} sm={12}>
                      <div style={{ fontSize: 18 }}>
                        Coupon applied: {this.state.couponInput}
                      </div>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>

            <Grid container justify="flex-end">
              <Grid item xs={12} sm={6}>
                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth={true}
                  onClick={() => this.purchase()}
                  size="large"
                  classes={{ sizeLarge: styles.largeButton }}
                >
                  {this.props.t("Purchase")} {this.props.isReload}
                </Button>
                <Button fullWidth={true} onClick={() => this.props.onBack()}>
                  {this.props.t("Back")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.showCoupon}
          disableBackdropClick
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <span style={{ paddingTop: "5px" }}>Apply Coupon</span>
            <Button
              onClick={() => this.setState({ showCoupon: false })}
              style={{ float: "right", paddingRight: 0 }}
            >
              <CancelIcon />
            </Button>
          </DialogTitle>
          <DialogContent className={styles.couponDialog}>
            <TextField
              style={{ width: "100%", marginBottom: "20px" }}
              autoFocus
              margin="dense"
              id="coupon"
              label="Coupon Code"
              error={!this.state.couponValid}
              onChange={(value) =>
                this.setState({ couponInput: value.target.value })
              }
              value={this.state.couponInput}
              helperText={!this.state.couponValid ? "Enter valid coupon" : ""}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.applyCoupon()}
              color="secondary"
              classes={{ sizeLarge: styles.largeButton }}
              disabled={this.state.couponProcess}
              variant="contained"
              fullWidth={true}
              style={{ marginBottom: 20 }}
              size="large"
            >
              {!this.state.couponProcess && <span>Apply Coupon</span>}
              {this.state.couponProcess && <span>Validating Coupon..</span>}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurantDetails: RestaurantSelectors.getRestaurantDetails(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(withTheme(withStyles(classes)(withTranslation()(CreditCard))))
);
