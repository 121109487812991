import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  FormControl,
  Box,
  TextField,
  InputAdornment,
  Hidden,
} from "@material-ui/core";
import moment from "moment";
import "react-add-to-calendar/dist/react-add-to-calendar.css";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import styles from "./style.module.scss";
import RestaurantActions, {
  RestaurantSelectors,
} from "../../Redux/RestaurantRedux";
import "moment/locale/fr";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Clear";
import giftCardsApi from "../../Services/giftCardsApi";
import { toast } from "react-toastify";

class CloverPayment extends Component {
  constructor(props) {
    super(props);

    var collect = new window.PoyntCollect(
      this.props.restaurantDetails.pcApiKey,
      "urn:aid:b4c6c80d-3895-4b75-b260-d54a86316bc5"
    );
    let couponInput = "";
    this.state = {
      collect: collect,
      processing: false,
      showCoupon: false,
      couponInput: couponInput,
      couponValid: true,
      couponInfoForReorder: {},
      couponProcess: false,
      discountAmount: "",
      couponType: "",
      couponValue: "",
      couponAppliedSuccess: false,
    };
  }

  componentDidMount() {
    const options = {
      iFrame: {
        width: "500px",
        // height: "400px",
        border: "1px",
        borderRadius: "4px",
        boxShadow:
          "0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)",
      },
      style: {
        theme: "default", // basic default theme
      },
      displayComponents: {
        firstName: false, // toggle these to true if you wish to show the forms
        lastName: false,
        emailAddress: false,
        submitButton: false,
        showEndingPage: true, // controls ending page
      },
    };
    var self = this;
    this.state.collect.mount("card-element", document, options);
    this.state.collect.on("ready", (event) => {
      // handle ready event, proceed.
    });
    this.state.collect.on("token", function (token) {
      self.setState({
        processing: true,
      });
      if (!self.state.processing) {
        self.props.onPurchase(token?.data);
      }
    });
  }

  handleSubmit = () => {
    this.state.collect.createToken();
  };

  closeCoupon = () => {
    this.setState({
      showCoupon: false,
      couponValid: true,
      couponInput: "",
    });
  };

  applyCoupon = () => {
    this.setState({
      couponProcess: true,
    });

    const requestObj = {
      api_key: null,
      business_id: this.props.restaurantDetails?.businessId,
      location_id: this.props.restaurantDetails?.locationId,
      coupon_code: this.state.couponInput,
      recipient_email: this.props.recipientEmail,
      amount: this.props.amount,
    };
    // console.log('Coupon Request Data', requestObj);

    let apiService = giftCardsApi.create();
    this.setState({ loading: true });
    apiService.couponValidate(requestObj).then((response) => {
      if (response.ok && response.data.status != "Failure") {
        this.setState({
          loading: false,
          showCoupon: false,
          couponProcess: false,
          couponAppliedSuccess: true,
          discountAmount: response.data.discount_amount,
          couponType: response.data.coupon_type,
          couponValue: response.data.coupon_value,
        });
        this.props.onCouponApplied(response.data.discount_amount, this.state.couponInput);
      } else {
        if (response.data.status == "Failure" && response.data.status_message) {
          toast.error(response.data.status_message);
        } else {
          toast.error(this.props.t("Coupon not worked! Please try again!"));
        }
        this.setState({
          loading: false,
          showCoupon: false,
          couponInput: "",
          couponProcess: false,
          couponAppliedSuccess: false,
        });
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { t, i18n } = this.props;
    if (navigator.language.indexOf("fr") > -1) {
      if (i18n.language !== "fr") {
        moment.locale("fr");
        this.props.i18n.changeLanguage("fr");
      }
    }
    if (this.props.processing && !this.state.processing) {
      this.setState({
        processing: true,
      });
    } else if (!this.props.processing && this.state.processing) {
      this.setState({
        processing: false,
      });
    }
    return (
      <>
        <div id="card-element"></div>
        <Grid item xs={12} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>

          <Grid container justify="flex-start" style={{ marginTop: 20 }}>
            {!this.props.isReload && (
              <Grid>
                {!this.state.couponAppliedSuccess && (
                  <Grid item xs={12} sm={3}>
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{ width: 200, marginBottom: 20 }}
                      size="large"
                      onClick={() =>
                        this.setState({ showCoupon: true })
                      }
                      classes={{ sizeLarge: styles.largeButton }}
                    >
                      Apply Coupon
                    </Button>
                  </Grid>
                )}
                {this.state.couponAppliedSuccess && (
                  <Grid item xs={12} sm={12}>
                    <div style={{ fontSize: 18 }}>
                      Coupon applied: {this.state.couponInput}
                    </div>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>

          <Hidden smDown>
            <Grid container justify="flex-end" style={{ marginTop: 20 }}>
              <Grid item xs={12} sm={6}>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ width: 200, marginBottom: 20 }}
                  size="large"
                  onClick={() => this.handleSubmit()}
                  classes={{ sizeLarge: styles.largeButton }}
                >
                  Purchase
                </Button>
                <Button fullWidth={true} onClick={() => this.props.onBack()}>
                  Back
                </Button>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid container justify="flex-end">
              <Grid item xs={10} sm={6}>
                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth={true}
                  style={{ marginBottom: 20 }}
                  size="large"
                  onClick={() => this.state.handleSubmit()}
                  classes={{ sizeLarge: styles.largeButton }}
                >
                  Purchase
                </Button>
                <Button fullWidth={true} onClick={() => this.props.onBack()}>
                  Back
                </Button>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        <Dialog
          open={this.state.showCoupon}
          disableBackdropClick
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <span style={{ paddingTop: "5px" }}>Apply Coupon</span>
            <Button
              onClick={() => this.setState({ showCoupon: false })}
              style={{ float: "right", paddingRight: 0 }}
            >
              <CancelIcon />
            </Button>
          </DialogTitle>
          <DialogContent className={styles.couponDialog}>
            <TextField
              style={{ width: "100%", marginBottom: "20px" }}
              autoFocus
              margin="dense"
              id="coupon"
              label="Coupon Code"
              error={!this.state.couponValid}
              onChange={(value) =>
                this.setState({ couponInput: value.target.value })
              }
              value={this.state.couponInput}
              helperText={!this.state.couponValid ? "Enter valid coupon" : ""}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.applyCoupon()}
              color="secondary"
              classes={{ sizeLarge: styles.largeButton }}
              disabled={this.state.couponProcess}
              variant="contained"
              fullWidth={true}
              style={{ marginBottom: 20 }}
              size="large"
            >
              {!this.state.couponProcess && <span>Apply Coupon</span>}
              {this.state.couponProcess && <span>Validating Coupon..</span>}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    restaurantDetails: RestaurantSelectors.getRestaurantDetails(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(withTheme(withTranslation()(CloverPayment)))
);
