import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

// Types and actions creator

const { Types, Creators } = createActions({
  setRestaurantDetails: ["businessName", "locationName"],
  setRestaurantDetailsSuccess: ["info"],
  setRestaurantDetailsError: {},
});

export const RestaurantStateTypes = Types;
export default Creators;

// Initial State
export const INITIAL_RESTAURANT_STATE = Immutable({
  businessName: null,
  businessLocation: null,
  businessId: null,
  locationId: null,
  logo: null,
  logoHasBusinessName: null,
  backgroundImage: null,
  loading: true,
  businessDomain: null,
  locationDomain: null,
  restaurantMessage: null,
  themeColor: "#B51441",
  businessAddress: null,
  businessCity: null,
  businessProvince: null,
  businessPostalCode: null,
  businessPhone: null,
  apiAccessKey: null,
  paymentGateway: null,
  pcApiKey: null,
  deviceId: null,
});

// Selectors to derive data from state
export const RestaurantSelectors = {
  getRestaurantDetails: (state) => {
    return {
      businessName: state.restaurant.businessName,
      businessLocation: state.restaurant.businessLocation,
      businessId: state.restaurant.businessId,
      locationId: state.restaurant.locationId,
      logo: state.restaurant.logo,
      logoHasBusinessName: state.restaurant.logoHasBusinessName,
      backgroundImage: state.restaurant.backgroundImage,
      loading: state.restaurant.loading,
      businessDomain: state.restaurant.businessDomain,
      locationDomain: state.restaurant.locationDomain,
      restaurantMessage: state.restaurant.restaurantMessage,
      themeColor: state.restaurant.themeColor,
      businessAddress: state.restaurant.businessAddress,
      businessCity: state.restaurant.businessCity,
      businessProvince: state.restaurant.businessProvince,
      businessPostalCode: state.restaurant.businessPostalCode,
      businessPhone: state.restaurant.businessPhone,
      apiAccessKey: state.restaurant.apiAccessKey,
      paymentGateway: state.restaurant.paymentGateway,
      deviceId: state.restaurant.deviceId,
      pcApiKey: state.restaurant.pcApiKey,
    };
  },
};

// Reducers

export const setRestaurantDetails = (state, { businessName, locationName }) => {
  return {
    loading: true,
    businessName: null,
    businessLocation: null,
    businessId: null,
    locationId: null,
    logo: null,
    logoHasBusinessName: null,
    backgroundImage: null,
    businessDomain: businessName,
    locationDomain: locationName,
    restaurantMessage: null,
    themeColor: "#B51441",
    businessAddress: null,
    businessCity: null,
    paymentGateway: null,
    businessProvince: null,
    businessPostalCode: null,
    businessPhone: null,
    apiAccessKey: null,
  };
};

export const setRestaurantDetailsSuccess = (state, { info }) => {
  console.log("Info", info);
  return {
    loading: false,
    businessName: info.business_name,
    businessLocation: info.business_location,
    businessId: info.business_id,
    locationId: info.location_id,
    logo: info.logo,
    logoHasBusinessName: info.logo_has_business_name,
    backgroundImage: info.background_image,
    businessDomain: state.businessDomain,
    locationDomain: state.locationDomain,
    restaurantMessage: null,
    themeColor: info.theme_color,
    paymentGateway: info.payment_gateway,
    businessAddress: info.business_address,
    businessCity: info.business_city,
    businessProvince: info.business_province,
    businessPostalCode: info.business_postal_code,
    businessPhone: info.business_phone,
    apiAccessKey: info.apiAccessKey,
    pcApiKey: info.pcApiKey,
    deviceId: info.deviceId,
  };
};

export const setRestaurantDetailsError = (state, { info }) => {
  return {
    loading: false,
    businessName: null,
    businessLocation: null,
    businessId: null,
    locationId: null,
    logo: null,
    logoHasBusinessName: null,
    backgroundImage: null,
    businessDomain: null,
    locationDomain: null,
    paymentGateway: null,
    restaurantMessage: null,
    themeColor: "#B51441",
    businessAddress: null,
    businessCity: null,
    businessProvince: null,
    businessPostalCode: null,
    businessPhone: null,
    apiAccessKey: null,
  };
};

// Setting reducers to the types

export const reducer = createReducer(INITIAL_RESTAURANT_STATE, {
  [Types.SET_RESTAURANT_DETAILS]: setRestaurantDetails,
  [Types.SET_RESTAURANT_DETAILS_SUCCESS]: setRestaurantDetailsSuccess,
  [Types.SET_RESTAURANT_DETAILS_ERROR]: setRestaurantDetailsError,
});
