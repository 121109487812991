import apisauce from "apisauce";
import { api_key } from "./apiKey";
import { baseUrl } from "./baseUrl";

const create = (baseURL = baseUrl) => {
  const restaurantApi = apisauce.create({
    baseURL,
  });

  const setRestaurantDetails = (businessName, locationName) => {
    const formData = new FormData();
    formData.append("business_name", businessName);
    formData.append("business_location", locationName);
    formData.append("api_key", api_key);
    return restaurantApi.post("getbusinessdetails.php", formData);
  };

  return {
    setRestaurantDetails,
  };
};

export default {
  create,
};
