import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them) 
const resources = {
  en: {
    translation: {
      "Buy New Card": "Buy New Card",
      "Reload Gift Card": "Reload Gift Card",
      "Check Balance": "Check Balance",
      "Purchase New Gift Card": "Purchase New Gift Card",
      "Recipient Name": "Recipient Name",
      "Recipient Email": "Recipient Email",
      "Recipient Phone Number": "Recipient Phone Number",
      "Your Name (Optional)": "Your Name (Optional)",
      "Your Message (Optional)": "Your Message (Optional)",
      "Next": "Next",
      "Please enter valid Name!": "Please enter valid Name!",
      "Please enter valid Email Address!": "Please enter valid Email Address!",
      "Please enter valid phone number!": "Please enter valid phone number!",
      "Please enter valid message!": "Please enter valid message!",
      "Please enter valid Amount!": "Please enter valid Amount!",
      "Please enter valid Gift Card Number!": "Please enter valid Gift Card Number!",
      "Please enter valid PIN!": "Please enter valid PIN!",
      "Invalid Gift Card number or Gift card pin!": "Invalid Gift Card number or Gift card pin!",
      "Payment Failed! Please try again!": "Payment Failed! Please try again!",
      "Please select captcha!": "Please select captcha!",
      "Please enter valid credit card number!": "Please enter valid credit card number!",
      "Please enter valid Name on Card!": "Please enter valid Name on Card!",
      "Please enter valid expiry date!": "Please enter valid expiry date!",
      "Please enter valid cvv!": "Please enter valid cvv!",
      "Please enter valid Postal Code!": "Please enter valid Postal Code!",
      "Reload Gift Card": "Reload Gift Card",
      "Verify": "Verify",
      "New Gift Card Balance": "New Gift Card Balance",
      "Gift Card Balance": "Gift Card Balance",
      "Check Gift Card Balance": "Check Gift Card Balance",
      "Gift Card Number": "Gift Card Number",
      "PIN": "PIN",
      "Add amount": "Add amount",
      "Continue": "Continue",
      "Back": "Back",
      "Purchase": "Purchase",
      "Reload this card": "Reload this card",
      "Gift Card Value": "Gift Card Value",
      "Credit Card": "Credit Card",
      "Name on Card": "Name on Card",
      "Expiry Date": "Expiry Date",
      "Zip/Postal Code": "Zip/Postal Code"
    }
  },
  fr: {
    translation: {
      "Buy New Card": "Acheter une nouvelle carte",
      "Reload Gift Card": "Recharger la carte-cadeau",
      "Check Balance": "Vérifier le solde",
      "Purchase New Gift Card": "Acheter une nouvelle carte-cadeau",
      "Recipient Name": "Nom du destinataire",
      "Recipient Email": "Destinataire E-mail",
      "Recipient Phone Number": "Numéro de téléphone du destinataire",
      "Your Name (Optional)": "Votre nom (facultatif)", 
      "Your Message (Optional)": "Votre message (facultatif)",
      "Next": "Prochain",
      "Please enter valid Name!": "Veuillez entrer un nom valide!",
      "Please enter valid Email Address!": "Veuillez saisir une adresse e-mail valide!",
      "Please enter valid phone number!": "S'il vous plait entrez un numéro de téléphone valide!",
      "Please enter valid message!": "ProVeuillez saisir un message valide!chain",
      "Please enter valid Amount!": "Veuillez entrer un montant valide!",
      "Please enter valid Gift Card Number!": "Veuillez saisir un numéro de carte-cadeau valide!",
      "Please enter valid PIN!": "Veuillez saisir un PIN valide!",
      "Invalid Gift Card number or Gift card pin!": "Numéro de carte-cadeau ou pin de carte-cadeau invalide!",
      "Payment Failed! Please try again!": "Paiement échoué! Veuillez réessayer!",
      "Reload Gift Card": "Recharger la carte-cadeau",
      "Verify": "vérifier",
      "New Gift Card Balance": "Nouveau solde de la carte-cadeau",
      "Gift Card Balance": "Solde de la carte-cadeau",
      "Check Gift Card Balance": "Vérifier le solde de la carte-cadeau",
      "Please select captcha!": "Veuillez sélectionner captcha!",
      "Please enter valid credit card number!": "S'il vous plait, veuillez entrer un numéro de carte de crédit valide!",
      "Please enter valid Name on Card!": "Veuillez saisir un nom valide sur la card!",
      "Please enter valid expiry date!": "Veuillez saisir une date d'expiration valide!",
      "Please enter valid cvv!": "Veuillez saisir un cvv valide!",
      "Please enter valid Postal Code!": "Veuillez entrer un code postal valide!",
      "Gift Card Number": "Numéro de carte-cadeau",
      "PIN": "ÉPINGLE",
      "Add amount": "Ajouter un montant",
      "Continue": "Continuer",
      "Back": "Arrière",
      "Purchase": "Achat",
      "Reload this card": "Recharger cette carte",
      "Gift Card Value": "Valeur de la carte-cadeau",
      "Credit Card": "Carte de crédit",
      "Name on Card": "Nom sur la carte",
      "Expiry Date": "Date d'expiration",
      "Zip/Postal Code": "Zip / code postal"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;