import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  FormControl,
  Box,
  TextField,
  InputAdornment,
  Hidden,
} from "@material-ui/core";
import moment from "moment";
import "react-add-to-calendar/dist/react-add-to-calendar.css";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import styles from "./style.module.scss";
import RestaurantActions, {
  RestaurantSelectors,
} from "../../Redux/RestaurantRedux";
import "moment/locale/fr";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Clear";
import giftCardsApi from "../../Services/giftCardsApi";
import { toast } from "react-toastify";

class CloverPayment extends Component {
  constructor(props) {
    super(props);
    let couponInput = "";
    this.state = {
      showCoupon: false,
      couponInput: couponInput,
      couponValid: true,
      couponInfoForReorder: {},
      couponProcess: false,
      discountAmount: "",
      couponType: "",
      couponValue: "",
      couponAppliedSuccess: false,
    };
  }

  componentDidMount() {
    this.clover = new window.Clover(this.props.restaurantDetails.apiAccessKey);
    const elements = this.clover.elements();
    console.log(this.clover);
    const styles = {
      "card-number input": {
        width: "100%",
        "font-size": "16px",
        border: "1px gray solid",
        "border-radius": "4px",
        padding: "20px 10px 20px 10px",
        "border-color": "rgba(0, 0, 0, 0.23)",
      },
      "card-date input": {
        width: "100%",
        "font-size": "16px",
        border: "1px gray solid",
        padding: "20px 10px 20px 10px",
        "border-radius": "4px",
        "border-color": "rgba(0, 0, 0, 0.23)",
      },
      "card-cvv input": {
        width: "100%",
        "font-size": "16px",
        border: "1px gray solid",
        "border-radius": "4px",
        padding: "20px 10px 20px 10px",
        "border-color": "rgba(0, 0, 0, 0.23)",
      },
      "card-postal-code input": {
        width: "100%",
        "font-size": "16px",
        border: "1px gray solid",
        "border-radius": "4px",
        padding: "20px 10px 20px 10px",
        "border-color": "rgba(0, 0, 0, 0.23)",
      },
      button: {
        padding: "10px",
      },
    };
    const cardNumber = elements.create("CARD_NUMBER", styles);
    const cardDate = elements.create("CARD_DATE", styles);
    const cardCvv = elements.create("CARD_CVV", styles);
    const cardPostalCode = elements.create("CARD_POSTAL_CODE", styles);
    cardNumber.mount("#card-number");
    cardDate.mount("#card-date");
    cardCvv.mount("#card-cvv");
    cardPostalCode.mount("#card-postal-code");
    const cardResponse = document.getElementById("card-response");
    const displayCardNumberError = document.getElementById(
      "card-number-errors"
    );
    const displayCardDateError = document.getElementById("card-date-errors");
    const displayCardCvvError = document.getElementById("card-cvv-errors");
    const displayCardPostalCodeError = document.getElementById(
      "card-postal-code-errors"
    );

    // Handle real-time validation errors from the card Element.
    cardNumber.addEventListener("change", function (event) {
      console.log(`cardNumber changed ${JSON.stringify(event)}`);
      displayCardNumberError.innerHTML = event.CARD_NUMBER.error || "";
    });
    cardNumber.addEventListener("blur", function (event) {
      console.log(`cardNumber blur ${JSON.stringify(event)}`);
      displayCardNumberError.innerHTML = event.CARD_NUMBER.error || "";
    });
    cardDate.addEventListener("change", function (event) {
      console.log(`cardDate changed ${JSON.stringify(event)}`);
      displayCardDateError.innerHTML = event.CARD_DATE.error || "";
    });
    cardDate.addEventListener("blur", function (event) {
      console.log(`cardDate blur ${JSON.stringify(event)}`);
      displayCardDateError.innerHTML = event.CARD_DATE.error || "";
    });
    cardCvv.addEventListener("change", function (event) {
      console.log(`cardCvv changed ${JSON.stringify(event)}`);
      displayCardCvvError.innerHTML = event.CARD_CVV.error || "";
    });
    cardCvv.addEventListener("blur", function (event) {
      console.log(`cardCvv blur ${JSON.stringify(event)}`);
      displayCardCvvError.innerHTML = event.CARD_CVV.error || "";
    });
    cardPostalCode.addEventListener("change", function (event) {
      console.log(`cardPostalCode changed ${JSON.stringify(event)}`);
      displayCardPostalCodeError.innerHTML = event.CARD_POSTAL_CODE.error || "";
    });
    cardPostalCode.addEventListener("blur", function (event) {
      console.log(`cardPostalCode blur ${JSON.stringify(event)}`);
      displayCardPostalCodeError.innerHTML = event.CARD_POSTAL_CODE.error || "";
    });
    // TODO: Register event handlers
    const form = document.getElementById("payment-form");
    let self = this;
    form.addEventListener("submit", function (event) {
      event.preventDefault();
      self.clover
        .createToken()
        .then(function (result) {
          if (result.errors) {
            Object.values(result.errors).forEach(function (value) {
              console.log(value);
            });
          } else {
            self.props.onPurchase(result.token);
          }
        })
        .catch(function (data) {
          console.log(data);
        });
    });
  }

  closeCoupon = () => {
    this.setState({
      showCoupon: false,
      couponValid: true,
      couponInput: "",
    });
  };

  applyCoupon = () => {
    this.setState({
      couponProcess: true,
    });

    const requestObj = {
      api_key: null,
      business_id: this.props.restaurantDetails?.businessId,
      location_id: this.props.restaurantDetails?.locationId,
      coupon_code: this.state.couponInput,
      recipient_email: this.props.recipientEmail,
      amount: this.props.amount,
    };
    // console.log('Coupon Request Data', requestObj);

    let apiService = giftCardsApi.create();
    this.setState({ loading: true });
    apiService.couponValidate(requestObj).then((response) => {
      if (response.ok && response.data.status != "Failure") {
        this.setState({
          loading: false,
          showCoupon: false,
          couponProcess: false,
          couponAppliedSuccess: true,
          discountAmount: response.data.discount_amount,
          couponType: response.data.coupon_type,
          couponValue: response.data.coupon_value,
        });
        this.props.onCouponApplied(response.data.discount_amount, this.state.couponInput);
      } else {
        if (response.data.status == "Failure" && response.data.status_message) {
          toast.error(response.data.status_message);
        } else {
          toast.error(this.props.t("Coupon not worked! Please try again!"));
        }
        this.setState({
          loading: false,
          showCoupon: false,
          couponInput: "",
          couponProcess: false,
          couponAppliedSuccess: false,
        });
      }
    });
  };

  handleSubmit = () => { };

  render() {
    const { classes } = this.props;
    const { t, i18n } = this.props;
    if (navigator.language.indexOf("fr") > -1) {
      if (i18n.language !== "fr") {
        moment.locale("fr");
        this.props.i18n.changeLanguage("fr");
      }
    }
    return (
      <>
        <form
          id="payment-form"
          onSubmit={this.handleSubmit}
          style={{ width: "100%" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div class="form-row top-row">
                <div
                  id="card-number"
                  className="field card-number"
                  style={{ height: 48 }}
                ></div>
                <div
                  class="input-errors"
                  id="card-number-errors"
                  role="alert"
                ></div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div class="form-row">
                <div
                  id="card-date"
                  className="field third-width"
                  style={{ height: 48 }}
                ></div>
                <div
                  class="input-errors"
                  id="card-date-errors"
                  role="alert"
                ></div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div class="form-row">
                <div
                  id="card-cvv"
                  className="field third-width"
                  style={{ height: 48 }}
                ></div>
                <div
                  class="input-errors"
                  id="card-cvv-errors"
                  role="alert"
                ></div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div class="form-row">
                <div
                  id="card-postal-code"
                  className="field third-width"
                  style={{ height: 48 }}
                ></div>
                <div
                  class="input-errors"
                  id="card-postal-code-errors"
                  role="alert"
                ></div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>

              <Grid container justify="flex-start">
                {!this.props.isReload && (
                  <Grid>
                    {!this.state.couponAppliedSuccess && (
                      <Grid item xs={12} sm={3}>
                        <Button
                          color="secondary"
                          variant="contained"
                          style={{ width: 200, marginBottom: 20 }}
                          size="large"
                          onClick={() =>
                            this.setState({ showCoupon: true })
                          }
                          classes={{ sizeLarge: styles.largeButton }}
                        >
                          Apply Coupon
                    </Button>
                      </Grid>
                    )}
                    {this.state.couponAppliedSuccess && (
                      <Grid item xs={12} sm={12}>
                        <div style={{ fontSize: 18 }}>
                          Coupon applied: {this.state.couponInput}
                        </div>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>

              <Hidden smDown>
                <Grid container justify="flex-end">
                  <Grid item xs={12} sm={6}>
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{ width: 200, marginBottom: 20 }}
                      size="large"
                      type="submit"
                      classes={{ sizeLarge: styles.largeButton }}
                    >
                      Purchase
                    </Button>
                    <Button
                      fullWidth={true}
                      onClick={() => this.props.onBack()}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid container justify="flex-end">
                  <Grid item xs={10} sm={6}>
                    <Button
                      color="secondary"
                      variant="contained"
                      fullWidth={true}
                      style={{ marginBottom: 20 }}
                      size="large"
                      type="submit"
                      classes={{ sizeLarge: styles.largeButton }}
                    >
                      Purchase
                    </Button>
                    <Button
                      fullWidth={true}
                      onClick={() => this.props.onBack()}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </form>
        <Dialog
          open={this.state.showCoupon}
          disableBackdropClick
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <span style={{ paddingTop: "5px" }}>Apply Coupon</span>
            <Button
              onClick={() => this.setState({ showCoupon: false })}
              style={{ float: "right", paddingRight: 0 }}
            >
              <CancelIcon />
            </Button>
          </DialogTitle>
          <DialogContent className={styles.couponDialog}>
            <TextField
              style={{ width: "100%", marginBottom: "20px" }}
              autoFocus
              margin="dense"
              id="coupon"
              label="Coupon Code"
              error={!this.state.couponValid}
              onChange={(value) =>
                this.setState({ couponInput: value.target.value })
              }
              value={this.state.couponInput}
              helperText={!this.state.couponValid ? "Enter valid coupon" : ""}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.applyCoupon()}
              color="secondary"
              classes={{ sizeLarge: styles.largeButton }}
              disabled={this.state.couponProcess}
              variant="contained"
              fullWidth={true}
              style={{ marginBottom: 20 }}
              size="large"
            >
              {!this.state.couponProcess && <span>Apply Coupon</span>}
              {this.state.couponProcess && <span>Validating Coupon..</span>}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    restaurantDetails: RestaurantSelectors.getRestaurantDetails(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(withTheme(withTranslation()(CloverPayment)))
);
