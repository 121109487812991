import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import {
  Grid,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardMedia,
  Button,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  Input,
  InputLabel,
  TextField,
  ListItemText,
  ButtonGroup,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import styles from "./style.module.scss";
import { Helmet } from "react-helmet";
import moment from "moment";
import RestaurantActions, {
  RestaurantSelectors,
} from "../../Redux/RestaurantRedux";
import Loading from "../../components/Loading/Loading";
import background3 from "../../assets/images/background3.png";
import giftCards from "../../assets/images/giftCards.jpg";
import "react-add-to-calendar/dist/react-add-to-calendar.css";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import "moment/locale/fr";
import MaskedInput from "react-text-mask";
import CreditCard from "../../components/CreditCard/CreditCard";
import giftCardsApi from "../../Services/giftCardsApi";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import CloverPayment from "../CloverPayment/CloverPayment";
import PoyntPayment from "../PoyntPayment/PoyntPayment";
import { encrypt } from "../../Services/crypt.service";

const classes = (theme) => {
  return {
    cardTitle: {
      backgroundColor: theme.palette.secondary.main,
      padding: 16,
      textAlign: "center",
      color: "white",
      fontSize: 16,
      fontWeight: 600,
    },
  };
};

function GiftCardMasking(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[
        /[1-9]/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      showMask
    />
  );
}

function PinNumerMasking(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[/[1-9]/, /\d/, /\d/, /\d/]}
      showMask
    />
  );
}

class CurrentCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      step: 1,
      businessName: "",
      giftCardNumber: "",
      giftCardNumberError: false,
      pin: "",
      pinError: false,
      name: "",
      nameError: false,
      message: "",
      messageError: false,
      amount: 100,
      amountError: false,
      currentBalance: null,
      currentRecipientName: null,
      maskedGiftCardNumber: null,
      reload: false,
    };
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    const restaurantDomain = this.props.match?.params?.restaurant;
    const locationDomain = this.props.match?.params?.location;
    if (
      !this.props.restaurantDetails.businessName ||
      this.props.restaurantDetails.businessDomain !== restaurantDomain ||
      this.props.restaurantDetails.locationDomain !== locationDomain
    ) {
      this.props.history.push("/" + restaurantDomain + "/" + locationDomain);
    }
    if (navigator.language.indexOf("fr") > -1) {
      this.props.i18n.changeLanguage("fr");
    } else {
      this.props.i18n.changeLanguage("en");
    }
    window.onbeforeunload = function () {
      return "Are you sure to leave this page?";
    };
  }

  changeLanguage = (lan) => {
    if (lan == "en") {
      this.props.i18n.changeLanguage("fr");
    } else {
      this.props.i18n.changeLanguage("en");
    }
  };

  goToPurchaseStep = () => {
    if (!/^([0-9]*[.])?[0-9]+$/.test(this.state.amount)) {
      this.setState({ amountError: true });
    } else {
      this.setState({ amountError: false, step: 3 });
    }
  };

  reloadGiftCard = (request) => {
    const requestObj = {
      api_key: null,
      business_id: this.props.restaurantDetails.businessId,
      location_id: this.props.restaurantDetails.locationId,
      gift_card_number: this.state.giftCardNumber,
      gift_card_pin: this.state.pin,
      card_number: encrypt(request.creditCardNumber),
      expire_month: request.expiryMonth,
      expire_year: request.expiryYear,
      cvv: encrypt(request.creditCardCvv),
      card_name: request.nameOnCreditCard,
      card_postal_code: request.creditCardZipCode,
      amount: this.state.amount,
    };

    let apiService = giftCardsApi.create();
    this.setState({ loading: true });

    apiService.reloadGiftCard(requestObj).then((response) => {
      if (response.ok && response.data.approved) {
        this.setState({
          step: 4,
          loading: false,
          businessName: "",
          recipientName: "",
          recipientNameError: false,
          recipientEmail: "",
          recipientEmailError: false,
          recipientPhone: "",
          recipientPhoneError: false,
          name: "",
          nameError: false,
          message: "",
          messageError: false,
          amount: 100,
          amountError: false,
        });
      } else {
        if (response.data.status == "Failure" && response.data.status_message) {
          toast.error(response.data.status_message);
        } else {
          toast.error(this.props.t("Payment Failed! Please try again!"));
        }
        this.setState({
          loading: false,
        });
      }
    });
  };

  reloadGiftCardWithClover = (token) => {
    const requestObj = {
      api_key: null,
      business_id: this.props.restaurantDetails.businessId,
      location_id: this.props.restaurantDetails.locationId,
      gift_card_number: this.state.giftCardNumber,
      gift_card_pin: this.state.pin,
      cloverToken: token,
      amount: this.state.amount,
    };

    let apiService = giftCardsApi.create();
    this.setState({ loading: true });

    apiService.reloadGiftCardClover(requestObj).then((response) => {
      if (response.ok && response.data.approved) {
        this.setState({
          step: 4,
          loading: false,
          businessName: "",
          recipientName: "",
          recipientNameError: false,
          recipientEmail: "",
          recipientEmailError: false,
          recipientPhone: "",
          recipientPhoneError: false,
          name: "",
          nameError: false,
          message: "",
          messageError: false,
          amount: 100,
          amountError: false,
        });
      } else {
        if (response.data.status == "Failure" && response.data.status_message) {
          toast.error(response.data.status_message);
        } else {
          toast.error(this.props.t("Payment Failed! Please try again!"));
        }
        this.setState({
          loading: false,
        });
      }
    });
  };
  reloadGiftCardWithPoynt = (token) => {
    const requestObj = {
      api_key: null,
      business_id: this.props.restaurantDetails.businessId,
      location_id: this.props.restaurantDetails.locationId,
      gift_card_number: this.state.giftCardNumber,
      gift_card_pin: this.state.pin,
      poynt_response: token,
      amount: this.state.amount,
    };

    let apiService = giftCardsApi.create();
    this.setState({ loading: true });

    apiService.reloadGiftCardPoynt(requestObj).then((response) => {
      if (response.ok && response.data.approved) {
        this.setState({
          step: 4,
          loading: false,
          businessName: "",
          recipientName: "",
          recipientNameError: false,
          recipientEmail: "",
          recipientEmailError: false,
          recipientPhone: "",
          recipientPhoneError: false,
          name: "",
          nameError: false,
          message: "",
          messageError: false,
          amount: 100,
          amountError: false,
        });
      } else {
        if (response.data.status == "Failure" && response.data.status_message) {
          toast.error(response.data.status_message);
        } else {
          toast.error(this.props.t("Payment Failed! Please try again!"));
        }
        this.setState({
          loading: false,
        });
      }
    });
  };

  handleGiftCardChange = () => (event) => {
    const value = event.target.value;
    if (value.length !== 12) {
      this.setState({
        giftCardNumber: value,
      });
    } else {
      this.setState({
        giftCardNumberError: false,
        giftCardNumber: value,
      });
    }
  };

  handlePinChange = () => (event) => {
    const value = event.target.value;
    if (value.length !== 4) {
      this.setState({
        pin: value,
      });
    } else {
      this.setState({
        pinError: false,
        pin: value,
      });
    }
  };

  goToAmountStep = () => {
    //Check if all the values are correct
    let giftCardNumberError = false;
    let pinError = false;

    // Name checking
    if (this.state.giftCardNumber?.length < 3) {
      giftCardNumberError = true;
    }

    // Name checking
    if (this.state.pin?.length !== 4) {
      pinError = true;
    }

    if (!giftCardNumberError && !pinError) {
      this.recaptchaRef.current.executeAsync().then((value) => {
        let apiService = giftCardsApi.create();
        this.setState({ loading: true });

        apiService
          .checkGiftCard(
            this.props.restaurantDetails.businessId,
            this.props.restaurantDetails.locationId,
            this.state.giftCardNumber,
            this.state.pin
          )
          .then((response) => {
            if (response.ok && response.data.balance) {
              this.setState({
                currentBalance: response.data.balance,
                currentRecipientName: response.data.recipient_name,
                maskedGiftCardNumber: response.data.gift_card_number,
                step: 2,
                pinError: false,
                giftCardNumberError: false,
                loading: false,
              });
            } else {
              toast.error(
                this.props.t("Invalid Gift Card number or Gift card pin!")
              );
              this.setState({
                loading: false,
              });
            }
          });
      });
    } else {
      this.setState({
        pinError,
        giftCardNumberError,
      });
    }
  };

  backButton = () => {
    if (this.state.step == 2) {
      this.setState({
        step: this.state.step - 1,
        currentBalance: null,
        currentRecipientName: null,
        maskedGiftCardNumber: null,
        giftCardNumber: null,
        pin: null,
        reload: false,
        amount: 100,
      });
    } else {
      this.setState({
        step: this.state.step - 1,
      });
    }
  };

  onChange = (event) => {
    console.log(event);
    this.setState({
      googleCaptchaToken: event,
    });
  };

  goToHome = () => {
    this.setState({
      loading: false,
      step: 1,
      businessName: "",
      giftCardNumber: "",
      giftCardNumberError: false,
      pin: "",
      pinError: false,
      name: "",
      nameError: false,
      message: "",
      messageError: false,
      amount: 100,
      amountError: false,
      currentBalance: null,
      currentRecipientName: null,
      maskedGiftCardNumber: null,
      reload: false,
    });
    this.props.history.push(
      "/" +
      this.props.restaurantDetails.businessDomain +
      "/" +
      this.props.restaurantDetails.locationDomain
    );
  };

  render() {
    console.log(this.props.type);
    const { classes } = this.props;
    const { t, i18n } = this.props;
    if (navigator.language.indexOf("fr") > -1) {
      if (i18n.language !== "fr") {
        this.props.i18n.changeLanguage("fr");
      }
    }

    return (
      <>
        {this.props.restaurantDetails?.businessName && (
          <Helmet>
            <title>
              {this.props.restaurantDetails?.businessName} | Gift Easy
            </title>
          </Helmet>
        )}
        {!this.props.restaurantDetails?.businessName && (
          <Helmet>
            <title>Gift Easy</title>
          </Helmet>
        )}
        <Box>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className={
              this.props.restaurantDetails.backgroundImage
                ? styles.restaurantBackgroundImage
                : styles.backgroudImage
            }
            style={{
              backgroundImage: this.props.restaurantDetails.backgroundImage
                ? "url(" + this.props.restaurantDetails.backgroundImage + ")"
                : "url(" + background3 + ")",
            }}
          >
            <Grid container justify="center" className={styles.mainSection}>
              <Grid item xs={12} className={styles.topSection}>
                {this.state.loading && (
                  <Grid item xs={12}>
                    <div style={{ marginBottom: 35 }}>
                      <Loading />
                    </div>
                  </Grid>
                )}
                {!this.state.loading && (
                  <Grid container justify="center" alignItems="center">
                    {this.props.restaurantDetails?.logo && (
                      <Grid item xs={12}>
                        <div
                          style={{ textAlign: "center" }}
                          onClick={() => this.goToHome()}
                        >
                          <img
                            className={styles.logo}
                            src={this.props.restaurantDetails?.logo}
                          />
                        </div>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      {this.props.restaurantDetails?.logoHasBusinessName !==
                        "1" && (
                          <h1 style={{ textAlign: "center" }}>
                            {this.props.restaurantDetails?.businessName}
                          </h1>
                        )}
                      <span style={{ textAlign: "center" }}>
                        {this.props.restaurantDetails?.businessLocation}
                      </span>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <h1 style={{ textAlign: "center", marginTop: 25 }}>
                          {(this.props.type == "reload" ||
                            (this.props.type == "checkBalance" &&
                              this.state.reload)) && (
                              <span>{this.props.t("Reload Gift Card")}</span>
                            )}
                          {this.props.type == "checkBalance" &&
                            !this.state.reload && (
                              <span>
                                {this.props.t("Check Gift Card Balance")}
                              </span>
                            )}
                        </h1>
                      </Grid>
                    </Grid>
                    {this.state.currentBalance && (
                      <Card
                        classes={{ root: styles.allergyWarningStyle }}
                        elevation={0}
                      >
                        <CardContent>
                          <Grid container justify="center" alignItems="top">
                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="row"
                                justify="center"
                                style={{ marginTop: 15, textAlign: "center" }}
                              >
                                <Grid item xs={4}>
                                  <div>{this.props.t("Gift Card Number")}</div>
                                  <div>
                                    <strong>
                                      {this.state.maskedGiftCardNumber}
                                    </strong>
                                  </div>
                                </Grid>
                                <Grid item xs={4}>
                                  <div>{this.props.t("Gift Card Balance")}</div>
                                  <div>
                                    <strong>
                                      $ {this.state.currentBalance}
                                    </strong>
                                  </div>
                                </Grid>
                                <Grid item xs={4}>
                                  <div>{this.props.t("Recipient Name")}</div>
                                  <div>
                                    <strong>
                                      {this.state.currentRecipientName}
                                    </strong>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    )}
                    {/* Step 1 for the Entering Information */}
                    {this.state.step == 1 && (
                      <Grid
                        container
                        spacing={2}
                        className={styles.cardContainer}
                        direction="row"
                        justify="center"
                      >
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            className={styles.formControl}
                            fullWidth={true}
                            variant="outlined"
                          >
                            <TextField
                              label={this.props.t("Gift Card Number")}
                              value={this.state.giftCardNumber}
                              variant="outlined"
                              onChange={(event) =>
                                this.setState({
                                  giftCardNumber: event.target.value,
                                })
                              }
                              error={this.state.giftCardNumberError}
                              helperText={
                                this.state.giftCardNumberError
                                  ? this.props.t(
                                    "Please enter valid Gift Card Number!"
                                  )
                                  : ""
                              }
                              InputProps={{
                                inputComponent: GiftCardMasking,
                                value: this.state.giftCardNumber,
                                onChange: this.handleGiftCardChange(),
                              }}
                            />
                          </FormControl>
                          <FormControl
                            className={styles.formControl}
                            style={{ marginTop: 10 }}
                            fullWidth={true}
                            variant="outlined"
                          >
                            <TextField
                              label={this.props.t("PIN")}
                              value={this.state.pin}
                              variant="outlined"
                              onChange={(event) => {
                                if (event.target.value.length !== 4) {
                                  this.setState({
                                    pin: event.target.value,
                                    pinError: true,
                                  });
                                } else {
                                  this.setState({
                                    pin: event.target.value,
                                    pinError: false,
                                  });
                                }
                              }}
                              error={this.state.pinError}
                              helperText={
                                this.state.pinError
                                  ? this.props.t("Please enter valid PIN!")
                                  : ""
                              }
                              InputProps={{
                                inputComponent: PinNumerMasking,
                                value: this.state.pin,
                                onChange: this.handlePinChange(),
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container justify="center">
                            <Grid item xs={12} sm={6} md={3}>
                              <ReCAPTCHA
                                ref={this.recaptchaRef}
                                size="invisible"
                                sitekey="6LfgDboZAAAAAGDRLJGarirHauoNOhV4ZAtz8y07"
                                onChange={this.onChange}
                              />
                              {this.state.captchaError && (
                                <div style={{ color: "red" }}>
                                  {this.props.t("Please select captcha!")}
                                </div>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <Button
                                color="secondary"
                                variant="contained"
                                fullWidth={true}
                                onClick={() => this.goToAmountStep()}
                                size="large"
                                classes={{ sizeLarge: styles.largeButton }}
                              >
                                {this.props.t("Verify")}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {/* Step 2 for Entering Amount */}
                    {this.state.step == 2 && (
                      <>
                        <Grid
                          container
                          spacing={2}
                          className={styles.cardContainer}
                          direction="row"
                          justify="center"
                        >
                          {(this.props.type == "reload" ||
                            (this.props.type == "checkBalance" &&
                              this.state.reload)) && (
                              <Grid item xs={12} md={6}>
                                <FormControl
                                  className={styles.formControl}
                                  fullWidth={true}
                                  variant="outlined"
                                >
                                  <InputLabel htmlFor="outlined-adornment-amount">
                                    {this.props.t("Add amount")}
                                  </InputLabel>
                                  <OutlinedInput
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "23px",
                                    }}
                                    label="Enter Amount"
                                    id="outlined-adornment-amount"
                                    value={this.state.amount}
                                    variant="outlined"
                                    onChange={(event) =>
                                      this.setState({
                                        amount: event.target.value,
                                      })
                                    }
                                    error={this.state.amountError}
                                    helperText={
                                      this.state.amountError
                                        ? this.props.t(
                                          "Please enter valid Amount!"
                                        )
                                        : ""
                                    }
                                    startAdornment={
                                      <InputAdornment position="start">
                                        <AttachMoneyIcon />
                                      </InputAdornment>
                                    }
                                    labelWidth={60}
                                  />
                                </FormControl>
                                <div
                                  style={{
                                    textAlign: "center",
                                    marginTop: 20,
                                  }}
                                >
                                  <ButtonGroup
                                    color="primary"
                                    aria-label="outlined primary button group"
                                  >
                                    <Button
                                      variant={
                                        this.state.amount == 25 ? "contained" : ""
                                      }
                                      color={
                                        this.state.amount == 25 ? "secondary" : ""
                                      }
                                      onClick={() =>
                                        this.setState({ amount: 25 })
                                      }
                                    >
                                      $25
                                  </Button>
                                    <Button
                                      variant={
                                        this.state.amount == 50 ? "contained" : ""
                                      }
                                      color={
                                        this.state.amount == 50 ? "secondary" : ""
                                      }
                                      onClick={() =>
                                        this.setState({ amount: 50 })
                                      }
                                    >
                                      $50
                                  </Button>
                                    <Button
                                      variant={
                                        this.state.amount == 100
                                          ? "contained"
                                          : ""
                                      }
                                      color={
                                        this.state.amount == 100
                                          ? "secondary"
                                          : ""
                                      }
                                      onClick={() =>
                                        this.setState({ amount: 100 })
                                      }
                                    >
                                      $100
                                  </Button>
                                    <Button
                                      variant={
                                        this.state.amount == 250
                                          ? "contained"
                                          : ""
                                      }
                                      color={
                                        this.state.amount == 250
                                          ? "secondary"
                                          : ""
                                      }
                                      onClick={() =>
                                        this.setState({ amount: 250 })
                                      }
                                    >
                                      $250
                                  </Button>
                                  </ButtonGroup>
                                </div>
                              </Grid>
                            )}
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="center"
                        >
                          <Grid item xs={10} sm={6} md={4}>
                            {(this.props.type == "reload" ||
                              (this.props.type == "checkBalance" &&
                                this.state.reload)) && (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  size="large"
                                  fullWidth={true}
                                  style={{ marginBottom: 20 }}
                                  classes={{ sizeLarge: styles.largeButton }}
                                  onClick={() => this.goToPurchaseStep()}
                                >
                                  {this.props.t("Continue")}
                                </Button>
                              )}
                            {this.props.type == "checkBalance" &&
                              !this.state.reload && (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  size="large"
                                  classes={{ sizeLarge: styles.largeButton }}
                                  fullWidth={true}
                                  style={{ marginBottom: 20 }}
                                  onClick={() =>
                                    this.setState({ reload: true })
                                  }
                                >
                                  {this.props.t("Reload this card")}
                                </Button>
                              )}
                            <Button
                              fullWidth={true}
                              onClick={() => this.backButton()}
                            >
                              {this.props.t("Back")}
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {this.state.step == 3 && (
                      <>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="center"
                          style={{ textAlign: "center", marginBottom: 10 }}
                        >
                          <Grid item xs={12}>
                            <div>{this.props.t("New Gift Card Balance")}</div>
                            <div>
                              <strong>
                                ${" "}
                                {(
                                  +this.state.amount +
                                  +this.state.currentBalance
                                ).toFixed(2)}
                              </strong>
                            </div>
                          </Grid>
                        </Grid>

                        {this.props.restaurantDetails.paymentGateway == 1 && (
                          <CreditCard
                            isReload={true}
                            onPurchase={(request) =>
                              this.reloadGiftCard(request)
                            }
                            onBack={() => this.setState({ step: 2 })}
                          />
                        )}
                        {this.props.restaurantDetails.paymentGateway == 2 && (
                          <CloverPayment
                            isReload={true}
                            onPurchase={(token) =>
                              this.reloadGiftCardWithClover(token)
                            }
                            onBack={() => this.setState({ step: 2 })}
                          />
                        )}
                        {this.props.restaurantDetails.paymentGateway == 3 && (
                          <PoyntPayment
                            isReload={true}
                            onPurchase={(token) =>
                              this.reloadGiftCardWithPoynt(token)
                            }
                            onBack={() => this.setState({ step: 2 })}
                          />
                        )}
                      </>
                    )}

                    {this.state.step == 4 && (
                      <>
                        <Grid
                          container
                          spacing={2}
                          className={styles.cardContainer}
                        >
                          <Grid item xs={12}>
                            <h3 style={{ textAlign: "center" }}>
                              Your Gift Card has been reloaded. If you have any
                              questions, please feel free to reach us at{" "}
                              <a
                                href={`tel: ${this.props.restaurantDetails?.businessPhone}`}
                              >
                                {this.props.restaurantDetails?.businessPhone}
                              </a>
                            </h3>
                          </Grid>
                          <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={() => this.goToHome()}
                              style={{ width: 300 }}
                              size="large"
                            >
                              {this.props.t("Purchase New Gift Card")}
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid
                      item
                      xs={12}
                      style={{
                        textAlign: "center",
                        marginBottom: 15,
                        fontSize: 14,
                      }}
                    >
                      <div className="webView">
                        <span style={{ whiteSpace: "nowrap" }}>
                          {this.props.restaurantDetails.businessName} |{" "}
                        </span>
                        {this.props.restaurantDetails.businessAddress},{" "}
                        {this.props.restaurantDetails.businessCity},{" "}
                        {this.props.restaurantDetails.businessProvince} ,{" "}
                        {this.props.restaurantDetails.businessPostalCode} |{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          {this.props.restaurantDetails.businessPhone}
                        </span>
                      </div>
                      <div className="mobileView">
                        <span style={{ whiteSpace: "nowrap" }}>
                          {this.props.restaurantDetails.businessName}
                        </span>
                        <br />
                        {this.props.restaurantDetails.businessAddress},{" "}
                        {this.props.restaurantDetails.businessCity},{" "}
                        {this.props.restaurantDetails.businessProvince} ,{" "}
                        {this.props.restaurantDetails.businessPostalCode} <br />
                        <span style={{ whiteSpace: "nowrap" }}>
                          {this.props.restaurantDetails.businessPhone}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justify="center">
                        <Grid item xs={6} sm={4} md={2}>
                          <Button
                            style={{
                              marginLeft: "auto",
                              height: "100%",
                            }}
                            onClick={(event) =>
                              this.changeLanguage(i18n.language)
                            }
                            color="inherit"
                          >
                            {this.state.languageHover ? (
                              <div className={styles.languageHover}>Change</div>
                            ) : i18n.language == "en" ? (
                              "French"
                            ) : (
                                  "English"
                                )}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurantDetails: RestaurantSelectors.getRestaurantDetails(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(
    withTheme(withStyles(classes)(withTranslation()(CurrentCard)))
  )
);
