import { call, put, delay } from "redux-saga/effects";
import RestaurantActions from "../Redux/RestaurantRedux";
import createUrlToScreen from "../Services/Utils";

export function* setRestaurantDetails(api, action) {
  const { businessName, locationName } = action;
  const response = yield call(
    api.setRestaurantDetails,
    businessName,
    locationName
  );
  if (response.ok && response.data.status !== "Failure") {
    const restaurantDetails = response.data;
    const isMigrated = restaurantDetails.is_migrated;

    const reloadUrl = `${process.env.REACT_APP_GIFT_EASY_PURCHASE_URL}${businessName}/${locationName}`;
    if (isMigrated) {
      window.location.replace(reloadUrl);
    }else{
      yield put(RestaurantActions.setRestaurantDetailsSuccess(restaurantDetails));
    }

  } else {
    yield put(RestaurantActions.setRestaurantDetailsError());
  }
}
